import React from 'react';
import getConfig from 'next/config';
import { Text, Box, Button } from 'rebass/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import Container from '../../atoms/Container';

import NetworkInfo from './NetworkInfo';
import TalentNetwork from './TalentNetwork';
import { talentNetworkHeroCtaClicked, claimYourProfileHeroCtaClicked } from '../../../redux/actions/layout';
import {
  claimCompanyProfileInHeroSelector,
  hasAdvancedDesignSelector,
  hideLogoInHeroSelector,
  hideNameInHeroSelector,
  hideNetworkInfoSelector,
  hideNumbersInHeroSelector,
  hideTnCtaInHeroSelector,
  makeFeatureSelector,
  organizationsStringSelector,
  portfolioCompanySelectorFilter,
  talentNetworkSelector,
} from '../../../redux/selectors/network';
import { isAuthenticatedSelector } from '../../../redux/selectors/user';
import { NetworkSchema } from '../../../schema/network';

const { publicRuntimeConfig } = getConfig();
const { getroAppUrl } = publicRuntimeConfig;

export const Hero = ({ network }) => {
  const claimCompanyProfileInHero = claimCompanyProfileInHeroSelector(network);
  const darkHeroText = makeFeatureSelector('dark_hero_text', network);
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  const hideNetworkInfo = hideNetworkInfoSelector(network);
  const hideLogoInHero = hideLogoInHeroSelector(network);
  const hideNameInHero = hideNameInHeroSelector(network);
  const hideNumbersInHero = hideNumbersInHeroSelector(network);
  const hideTnCtaInHero = hideTnCtaInHeroSelector(network);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const talentNetwork = talentNetworkSelector(network);
  const organizationsString = organizationsStringSelector(true, network);
  const orgString = portfolioCompanySelectorFilter(network);
  const dispatch = useDispatch();
  const { tagline, subtitle, mainImageUrl, name, tnSettings, heroColorMask, slug } = network;
  const networkClass =
    network.loaded && network.id && network.features.indexOf('advanced_design') > -1
      ? `custom-design-${network.label}`
      : '';

  const textColor = darkHeroText ? 'text.dark' : 'white';
  const heroStyle = {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '@media (min-width: 480px)': {
      padding: 'calc(10 * 0.5rem) 0',
    },
  };

  if (heroColorMask) {
    heroStyle['&::before'] = {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: heroColorMask,
      zIndex: 2,
    };
  }

  return (
    <Box
      data-testid="default-hero"
      textAlign="center"
      py={[4]}
      color={textColor}
      sx={heroStyle}
      className="network-hero"
      fill
    >
      {mainImageUrl && (
        <Box
          as={Image}
          alt="hero"
          src={mainImageUrl}
          quality={100}
          fill
          sizes="100vw"
          style={{
            objectFit: 'cover',
          }}
        />
      )}

      <Container
        className={`container ${networkClass}`.trim()}
        sx={{
          mx: 'auto !important',
          boxSizing: 'border-box',
          maxWidth: '100%',
          position: 'relative',
          zIndex: 3,
        }}
      >
        {tagline && (
          <Text
            data-testid="tagline"
            as="h2"
            textAlign="center"
            fontSize={[3, '32px']}
            fontWeight="medium"
            fontFamily="inherit"
            sx={{
              maxWidth: '900px',
              position: 'relative',
            }}
            mx="auto"
            my="0"
            color="inherit"
            dangerouslySetInnerHTML={{ __html: tagline }}
          />
        )}
        {subtitle && (
          <Text
            data-testid="subtitle"
            className="subtitle"
            fontWeight="default"
            fontStyle="italic"
            color="inherit"
            fontSize={[2, 3]}
            mt={3}
            mx="auto"
            mb="0"
            sx={{
              position: 'relative',
              '&:first-child': {
                marginTop: '0',
              },
            }}
          >
            <Box as="span" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </Text>
        )}

        {!hideNetworkInfo && (
          <NetworkInfo
            data-testid="network-info"
            hasAdvancedDesign={hasAdvancedDesign}
            hideNumbersInHero={hideNumbersInHero}
            hideLogoInHero={hideLogoInHero}
            hideNameInHero={hideNameInHero}
            network={network}
            organizationsString={organizationsString}
          />
        )}

        {talentNetwork && !hideTnCtaInHero && !isAuthenticated && (
          <TalentNetwork
            onTalentNetworkHeroCTAClick={() => dispatch(talentNetworkHeroCtaClicked({ network }))}
            {...tnSettings}
          />
        )}

        {claimCompanyProfileInHero && (
          <Box mt={4} fs={2} sx={{ position: 'relative', color: 'inherit' }} data-testid="claim-your-profile">
            If you are a {name} {orgString},&nbsp;
            <Button
              className="theme_only"
              as="a"
              variant="anchorLink"
              color={textColor}
              display="inline-block"
              onClick={() => dispatch(claimYourProfileHeroCtaClicked({ network }))}
              href={`${getroAppUrl}claim-your-profile/${slug}`}
              target="_blank"
              rel="noreferrer"
            >
              claim your profile
            </Button>
            .
          </Box>
        )}
      </Container>
    </Box>
  );
};

Hero.propTypes = {
  network: NetworkSchema.isRequired,
};

export default Hero;
