import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { Button, Flex, Box, Text } from 'rebass/styled-components';

const TalentNetwork = ({ tnCtaButtonText, tnCtaDescriptionText, tnCtaTitleText, onTalentNetworkHeroCTAClick }) => (
  <Flex
    fontSize={[2, 3]}
    sx={{
      maxWidth: ['350px', '520px'],
      '&:not(:first-child)': {
        marginTop: [4, 5, 5],
      },
    }}
    alignItems="center"
    flexDirection="column"
    mx="auto"
    mb={0}
    className="main-hero-cta"
  >
    {tnCtaTitleText && (
      <Text fontWeight="semibold" fontSize={[2, 4]} mb={3} className="main-hero-cta-title">
        {tnCtaTitleText}
      </Text>
    )}
    {tnCtaDescriptionText && (
      <Text
        fontSize={[2, 3]}
        mb={3}
        className="main-hero-cta-text"
        dangerouslySetInnerHTML={{ __html: tnCtaDescriptionText }}
      />
    )}

    {tnCtaButtonText && (
      <Box mt={3}>
        <NextLink href="/onboarding" passHref legacyBehavior>
          <Button
            width={1}
            className="theme_only"
            variant="heroCta"
            onClick={onTalentNetworkHeroCTAClick}
            data-testid="talent-network-hero-cta"
            as="a"
            fontFamily="inherit"
          >
            {tnCtaButtonText}
          </Button>
        </NextLink>
      </Box>
    )}
  </Flex>
);

TalentNetwork.defaultProps = {
  tnCtaButtonText: '',
  tnCtaDescriptionText: '',
  tnCtaTitleText: '',
  onTalentNetworkHeroCTAClick: () => {},
};

TalentNetwork.propTypes = {
  tnCtaButtonText: PropTypes.string,
  tnCtaDescriptionText: PropTypes.string,
  tnCtaTitleText: PropTypes.string,
  onTalentNetworkHeroCTAClick: PropTypes.func,
};

export default TalentNetwork;
