import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text } from 'rebass/styled-components';

const Numbers = ({ label, value, hideLogoInHero, ...rest }) => (
  <Flex flexDirection="column" textAlign={hideLogoInHero ? 'center' : 'left'} className="stat-box stats" {...rest}>
    <Text fontSize={[hideLogoInHero ? 3 : 4, hideLogoInHero ? '36px' : '30px']} fontWeight="medium" className="number">
      {value.toLocaleString('en-US')}
    </Text>
    <Text sx={{ textTransform: 'uppercase' }} fontSize={0} className="text">
      {label}
    </Text>
  </Flex>
);

Numbers.propTypes = {
  hideLogoInHero: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default Numbers;
