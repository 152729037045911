import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Link } from 'rebass/styled-components';

import { ProfilePicture } from '@getro/rombo';

import Numbers from './Numbers';
import { optimizeImage } from '../../../helpers/fileHelpers';

const NetworkInfo = ({
  hasAdvancedDesign,
  hideNumbersInHero,
  hideLogoInHero,
  hideNameInHero,
  network,
  organizationsString,
}) => {
  const { logoUrl, domain, name, companiesCount, jobsCount } = network;
  const optimizedLogoUrl = optimizeImage(logoUrl);
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      className="network-intro"
      sx={{
        '&:not(:first-child)': {
          marginTop: [4, 5, 5],
        },
      }}
    >
      {!hideLogoInHero && (
        <Box width={['64px', '88px']} height={['64px', '88px']} mr={[3]}>
          <Link
            className="theme_only"
            width={['64px', '88px']}
            alignSelf="flex-start"
            href={`https://${domain}`}
            target="_blank"
          >
            <ProfilePicture data-testid="network-logo" imageUrl={optimizedLogoUrl} variant="square" name={name} />
          </Link>
        </Box>
      )}

      <Box className="info" sx={{ maxWidth: '300px' }}>
        {!hasAdvancedDesign && !hideNameInHero && (
          <Link
            data-testid="network-info-name"
            className="theme_only"
            display="block"
            fontWeight="medium"
            fontSize={[3, 6]}
            variant="buttons.anchorLink"
            textAlign={hideLogoInHero ? 'center' : 'left'}
            href={`https://${domain}`}
            target="_blank"
            sx={{ textDecoration: 'none' }}
            hideNumbersInHero={hideNumbersInHero}
          >
            {name}
          </Link>
        )}

        {!hideNumbersInHero && (
          <Flex
            data-testid="number-wrapper"
            justifyContent={hideLogoInHero ? 'space-around' : 'flex-start'}
            mt={!hasAdvancedDesign && !hideNameInHero ? [3, 2] : 0}
            className="stats"
          >
            <Box
              data-testid="org-count"
              as={Numbers}
              pr={5}
              label={organizationsString}
              value={companiesCount}
              hideLogoInHero={hideLogoInHero}
            />
            <Box data-testid="job-count" as={Numbers} label="Jobs" value={jobsCount} hideLogoInHero={hideLogoInHero} />
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

NetworkInfo.propTypes = {
  hasAdvancedDesign: PropTypes.bool.isRequired,
  hideNumbersInHero: PropTypes.bool.isRequired,
  hideLogoInHero: PropTypes.bool.isRequired,
  hideNameInHero: PropTypes.bool.isRequired,
  organizationsString: PropTypes.string.isRequired,
  network: PropTypes.shape({
    logoUrl: PropTypes.string,
    domain: PropTypes.string,
    name: PropTypes.string.isRequired,
    tagline: PropTypes.string,
    subtitle: PropTypes.string,
    companiesCount: PropTypes.number.isRequired,
    jobsCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default NetworkInfo;
